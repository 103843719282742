import React from 'react';
import "./pagesStyles.css";
import PagesLayout from "../components/PageLayout";
import MediaCardOrionUnlock from '../cards/cardsHomePage/MediacardOrionUnlock';
import OrionUnlock from './OrionUnlock';

const Products = () => {
    return (
      <PagesLayout>
            <div>
              <OrionUnlock />
            </div>
        </PagesLayout>
      );
    };

export default Products;
