import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import smslogo from '../../photos/smslogo.gif';
import { NavLink } from 'react-router-dom';

const styles = {
  card: {
    margin: "120px auto 50px",
    maxWidth: 345,
    overflow: "visible"
  },
  media: {
    margin: "-70px auto 0",
    width: "100%",
    height: 200,
    borderRadius: "4px",
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)",
    position: "relative",
    zIndex: 1000
  }
};

function MediaCardGarage(props) {
  const { classes } = props;
  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={smslogo}
          title="Orion Garage"
        />
        <CardContent>
          <Typography gutterBottom variant="headline" component="h2">
            Orion Garage
          </Typography>
          <Typography component="p">
            Orion Garage is an access control system for garage doors who will offer various functionalities...
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
      <Button size="small" color="primary">
                  <NavLink 
                  exact to="/orionGarage" 
                  className="nav-link-card">
                  SOON...   
                  </NavLink>
        </Button>
      </CardActions>
    </Card>
  );
}

MediaCardGarage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MediaCardGarage);