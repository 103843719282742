
import firebase from 'firebase';

// Used to Grab onto Firebase -> Connection
var firebaseConfig = {
    apiKey: "AIzaSyBVdHLep0GS1BEA35F7ixpVhzWzbM6LWFo",
    authDomain: "simple-mobile-solutions-web.firebaseapp.com",
    projectId: "simple-mobile-solutions-web",
    storageBucket: "simple-mobile-solutions-web.appspot.com",
    messagingSenderId: "887340001771",
    appId: "1:887340001771:web:0c6446e443281d90ff51ae",
    measurementId: "G-M1W3YB6RB2"
    // apiKey: "AIzaSyDWoMMXqv4Axsoc87nieA3OaeLB2qVKTuk",
    // authDomain: "sms-web-page-04-2021.firebaseapp.com",
    // projectId: "sms-web-page-04-2021",
    // storageBucket: "sms-web-page-04-2021.appspot.com",
    // messagingSenderId: "156076016491",
    // appId: "1:156076016491:web:400844fdf8be8301eb822e",
    // measurementId: "G-424WVCMHC5"
  };
  // Initialize Firebase
  var fire = firebase.initializeApp(firebaseConfig);

  export default fire;
