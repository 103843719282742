import React from 'react';
import * as ReactBootStrap from 'react-bootstrap';
import '../../src/App.css';

function NavBar() {
  return (
    <div className="App">
      <ReactBootStrap.Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
  <ReactBootStrap.Navbar.Brand href="home">Simple Mobile Solutions</ReactBootStrap.Navbar.Brand>
  <ReactBootStrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <ReactBootStrap.Navbar.Collapse id="responsive-navbar-nav">
    <ReactBootStrap.Nav className="mr-auto">
      <ReactBootStrap.Nav.Link href="home">HOME</ReactBootStrap.Nav.Link>
      {/* <ReactBootStrap.Nav.Link href="about">About Us</ReactBootStrap.Nav.Link> */}
      <ReactBootStrap.NavDropdown title="PRODUCTS" id="collasible-nav-dropdown">
        <ReactBootStrap.NavDropdown.Item id="basic-nav-dropdown1" href="orionUnlock">Orion Unlock</ReactBootStrap.NavDropdown.Item>
        <ReactBootStrap.NavDropdown.Item id="basic-nav-dropdown1" href="orionalarm">Orion 3</ReactBootStrap.NavDropdown.Item>
        <ReactBootStrap.NavDropdown.Item id="basic-nav-dropdown1" href="oriongarage">Orion Garage</ReactBootStrap.NavDropdown.Item>
        {/* <ReactBootStrap.NavDropdown.Divider id="basic-nav-dropdown2" />
        <ReactBootStrap.NavDropdown.Item id="basic-nav-dropdown1" href="">Upcoming products</ReactBootStrap.NavDropdown.Item> */}
      </ReactBootStrap.NavDropdown>
    </ReactBootStrap.Nav>
    <ReactBootStrap.Nav>
      <ReactBootStrap.Nav.Link href="contact">CONTACT</ReactBootStrap.Nav.Link>
      <ReactBootStrap.Nav.Link eventKey={2} href="keVidimeSho">
                            
      </ReactBootStrap.Nav.Link>
    </ReactBootStrap.Nav>
  </ReactBootStrap.Navbar.Collapse>
</ReactBootStrap.Navbar>
    </div>
  );
}

export default NavBar;
