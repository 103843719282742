import React from 'react';
import "./pagesStyles.css";
import PagesLayout from "../components/PageLayout";
import MediaCardOrionUnlock from '../cards/cardsHomePage/MediacardOrionUnlock';
import ReactPlayer from "react-player";
import predalogo from "../photos/predalogo.jpg";
import Button from "@material-ui/core/Button";
import { NavLink } from 'react-router-dom';

const OrionUnlock = () => {
  return (
    <PagesLayout>
      <div class="flex-container">

        <div class="flex-child">
          <div class="oriontitle">
            <p> </p>
            <b>ORION UNLOCK access control system</b>
          </div>

          <div className="magenta">
            <MediaCardOrionUnlock />
          </div>
          <div className="blue">
            <div className="player-wrapper">
              <ReactPlayer
                className='react-player'
                url="https://www.youtube.com/watch?v=ECSWh0cwpPQ"
                controls
                volume={0.5}
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div className="preda">
            <div className='predaimage'>
              <img src={predalogo} alt="preda_logo" width="80" height="40" />
            </div>
            <div className='predatext'>
              <p>
              The project is supported by a grant from the Foundation for Sustainable Development "Preda Plus" through the local partnership for employment within the framework of the project of the International Labor Organization Strengthening the social dialogue financed by the European Union.
              </p>
            </div>
          </div>
        </div>

        <div class="flex-child">
          <div className="green">
            <p><b> FUNCTIONALITIES: </b></p>
            <p><b>1. Opening one or more front doors from anywhere in the world via a mobile application with internet</b></p>
 <p><b>2. Opening entrance doors with UID tags/tokens with NFC technology</b></p>
 <p><b>3. Event history</b></p>
 <p><b>4. Real-time notifications on your mobile phone</b></p>
 <p><b>5. Users can add/delete UID tag/token via mobile app with NFC (no extra cost) </b></p>
 <p> </p>
 <p><b>SAFETY &amp; UNIQUENESS: </b></p>
 <p><b>6. Users are notified when someone tries to force entry (vibration sensor)</b></p>
 <p><b>7. Users can use all the functionalities of the application even when they don't have internet</b></p>
 <p><b>8. Orion Unlock integrates institutions (Post, Ambulance, Police, Electricity etc) </b></p>
 <p><b>9. Safe from bypassing (it is impossible to bypass the electronics and open the door) </b></p>
 <p><b>10. Encrypted data, privacy policy </b></p>
 <p><b>11. Contactless opening (no keyboard), prevents the transmission of viruses and bacteria </b></p>
 <p><b>12. One year warranty with the possibility of extending the warranty period </b></p>

          </div>
          <div className="naracaj">
            <Button variant="outlined">
              <NavLink
                exact to="/contact"
                style={{ color: 'black' }} >
                ORDER
              </NavLink>
            </Button>
          </div>
        </div>

      </div>
    </PagesLayout>
  );
};
export default OrionUnlock;
