import React from 'react';
import "./pagesStyles.css";
import PagesLayout from "../components/PageLayout";
import MediaCardGarage from '../cards/cardsHomePage/MediacardGarage';
import Grid from '@material-ui/core/Grid';


const OrionGarage = () => {
    return (
        <PagesLayout>   
        <div class="flex-container">

            <div class="flex-child magenta1">
                <div>
                     <MediaCardGarage 
                    className="magenta1" />
                </div>
                <div className='wrapper1'>
                    <p>The page is under construction...</p>
                </div>
            </div>
        </div>
        </PagesLayout>
      );
    };
export default OrionGarage;
