import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from '../src/components/ScrollToTop.js'

//import pages
import Home from '../src/pages/Home';
import Contact from '../src/pages/Contact';
import Products from '../src/pages/Products';
import OrionUnlock from '../src/pages/OrionUnlock';
import OrionAlarm from '../src/pages/OrionAlarm';
import OrionGarage from '../src/pages/OrionGarage';
import PrivacyPolicy from './pages/privacy/PrivacyPolicyOrion';
import PrivacyPolicyBulls from './pages/privacy/PrivacyPolicyBulls';


ReactDOM.render(
  <React.StrictMode>
   <Router onUpdate={() => window.scrollTo(0, 0)}>
   <ScrollToTop />
     <Switch>
     <Route exact path="/">
         <Home />
        </Route>
       <Route exact path="/home">
         <Home />
        </Route>
        <Route path="/products">
         <Products />
        </Route>
        <Route path="/contact">
         <Contact />
        </Route>
        <Route exact path="/orionunlock">
         <OrionUnlock />
        </Route>
        <Route exact path="/orionalarm">
        <OrionAlarm />
        </Route>
        <Route exact path="/oriongarage">
          <OrionGarage />
        </Route>
        <Route exact path="/policy/orion">
          <PrivacyPolicy/>
        </Route>
        <Route exact path="/policy/marketbulls">
          <PrivacyPolicyBulls />
        </Route>
     </Switch>
   </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
