import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import '../../pages/pagesStyles.css';
import orionimage from '../../photos/orionimage.png';
import playstore from '../../photos/playstore.jpg';
import appgallery from '../../photos/appgallery.png';
import appstore from '../../photos/appstore.png';

const styles = {
  card: {
    margin: "120px auto 50px",
    maxWidth: 345,
    minWidth: 200,
    overflow: "visible"

  },

  media: {
    margin: "-70px auto 0",
    width: "100%",
    height: 200,
    borderRadius: "4px",
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)",
    position: "relative",
    
  }
};

function MediaCard(props) {
  const { classes } = props;
  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={orionimage}
          title="Orion Unlock"
        />
        <CardContent>
          <Typography gutterBottom variant="headline" component="h6">
            <p>Install the mobile application on the following link:</p>
          <a href="https://play.google.com/store/apps/details?id=solutions.simplemobile.orionunlock&fbclid=IwAR2bsUJOlyaGGJh1pY_LUQ0dG7YDTd5KOCFViWJqF7RBEolaFZ-lZeWWIKs" target="_blank" >
<img src={playstore} alt="Play_store_icon" width="160" height="55" /></a>
<p> </p>
<a href="https://apps.apple.com/be/app/orion-unlock/id1580995862" target="_blank" >
<img src={appstore} alt="App_store_icon" width="160" height="55" /></a>
<p> </p>
<a href="https://apkcombo.com/orion-unlock/solutions.simplemobile.orionunlock/" target="_blank">
<img src={appgallery} alt="Huawei_store_icon" width="160" height="55" /></a>

          </Typography>
          {/* <Typography component="p">
            Orion Unlock is a door control system that offers high functionality for its users...
          </Typography> */}
        </CardContent>
      </CardActionArea>
    </Card>
  ); 
}

MediaCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MediaCard);