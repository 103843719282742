import React from 'react';
import pagesStyles from './pagesStyles.css';
import * as ReactBootStrap from 'react-bootstrap';

function Footer() {
    return (
        <div class="footer-dark">
        <footer>
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 col-md-3 item">
                        <h3>Contact:</h3>
                        <ul>
                            <li><a href="#">E-mail: simplemobilesolutionsmk@gmail.com</a></li>
                            <li><a href="#">Phone: +389 70 525 845</a></li>
                            <li><a href="#">___________________________________________</a></li>
                            <li><a href="https://www.facebook.com/simplemobilesolutionsmk" target="_blank"> Facebook </a></li>
                            <li><a href="https://www.instagram.com/simplemobile.solutions/" target="_blank"> Instagram </a></li>
                            <li><a href="https://www.linkedin.com/company/simple-mobile-solutions/" target="_blank"> LinkedIn </a></li>
                        </ul>
                    </div>
                    {/* <div class="col-sm-6 col-md-3 item">
                        <h3>About</h3>
                        <ul>
                            <li><a href="#">Company</a></li>
                            <li><a href="#">Team</a></li>
                            <li><a href="#">Careers</a></li>
                        </ul>
                    </div> */}
                    <div class="col-md-6 item text">
                        <h3></h3>
                        <p></p>
                    </div>
                    <div class="col item social">
                        <a href="https://www.facebook.com/simplemobilesolutionsmk"><i class="icon ion-social-facebook"></i></a>
                        {/* <a href="#"><i class="icon ion-social-twitter"></i></a> */}
                        <a href="https://www.linkedin.com/company/simple-mobile-solutions/"><i class="icon ion-social-linkedin"></i></a>
                        <a href="https://www.instagram.com/simplemobile.solutions/"><i class="icon ion-social-instagram"></i></a></div>
                </div>
                <p class="copyright">Simple mobile solutions © 2019</p>
            </div>
            <div className="copyright1">
            <a href="/policy/orion" style={{ color: "#f0f9ff" }} >Privacy Policy</a>
            </div>
        </footer>
    </div>
    );
}
export default Footer;