import React from 'react';
import "../pagesStyles.css";
import PagesLayout from "../../components/PageLayout";


const PrivacyPolicyBulls = () => {
    return (
        <PagesLayout>   
        <div class="flex-container"> 

        <div >
<div className="policy">
<h3><b> Privacy Policy - Market Bulls</b></h3>
<h5>Simple Mobile Solutions LLC - Global Internet Privacy Policy</h5>
<p>Simple Mobile Solutions LLC, respects your right to privacy. This policy summarizes what personally identifiable information and other information we may collect, how we may use this information and other important topics relating to your privacy and data protection.</p>
<h6>Your Consent</h6> 
<p>By using the Mobile App, you agree to the terms of this Privacy Policy. Whenever you submit information via the App, you consent to the collection, use and disclosure of that information as described in this Policy.</p>
<h6>Limitations on the Collection, Use and Disclosure of Personal Information</h6>
<p>To the extent required by applicable law, whenever Simple Mobile Solutions LLC collects personal information through the Mobile App, Simple Mobile Solutions LLC will:</p>
<ul>
<li>collect, use, disclose and transfer your personal information only for legal requirements</li>
<li>collect your personal information only for specific, limited purposes. The information we collect will be relevant, adequate and not excessive for the purposes for which it is collected;</li>
<li>process your personal information in a manner consistent with the purposes for which it was originally collected </li>
<li>take commercially reasonable steps to ensure that your personal information is reliable for its intended use, accurate, complete, and, where necessary, kept up-to-date;</li>
<li>take appropriate measures to provide adequate protection for personal information that is disclosed to a third party or transferred to another country, including transfers within Simple Mobile Solutions LLC.</li>
</ul>
<h5>Information Collected by Simple Mobile Solutions LLC and How It May Be Used</h5>
<h6>Information You Voluntarily Provide</h6>

<p>Simple Mobile Solutions LLC collects the information you knowingly and voluntarily provide in order to benefit from a feature or participate in an activity given by the App. For example, Simple Mobile Solutions LLC may collect your information so you may receive email alerts or when you ask for product information, ask a question or email us with feedback. In many cases this information may include collection of personal information.</p>
<p>Simple Mobile Solutions LLC only uses your information for the purposes for which you provide it. For example, if you provide your e-mail address when you sign up to receive e-mail alerts, we use your e-mail address to deliver the e-mail alerts you request.</p>
<p>We may also use the information we collect for various business purposes such as customer service, fraud prevention, improving our products and services, or providing you with information and offers we believe may be of interest to you. We may also remove all the personally identifiable information and use the rest for historical, statistical or scientific purposes.</p>
<h6>Use and Sharing of Your Information</h6>
<p>Simple Mobile Solutions LLC will not sell personal information collected to mailing list brokers without your express consent.</p>
<p>Simple Mobile Solutions LLC will not disclose personal information about our users except as described here:</p>
<p>Except as specifically stated, we may use information collected from the Mobile App to improve the content of the App, to customize the App to your preferences, to communicate information to you (if you have requested it) for our marketing and research purposes, and for any other purposes specified in the App.</p>
<p>Simple Mobile Solutions LLC may share your personal information with other Simple Mobile Solutions LLC business units.  When we do so, these other Simple Mobile Solutions LLC business units will use your information in a manner consistent with the purposes for which it was originally collected (or to which you later consented).</p>
<p>Simple Mobile Solutions LLC may also share your personal information with third parties we hire to perform support services for us. These third parties are required to use the personal information we share with them only to perform services on our behalf and to treat your personal information as strictly confidential in compliance with all applicable privacy and data protection laws.</p>
<p>In some cases, Simple Mobile Solutions LLC may share your personal information with third parties who partner with us to provide products and services to our customers. If so, we will require our business partners to use that information in a manner consistent with the purposes for which it was originally collected (or to which you subsequently consented) and only as permitted under this Policy and all applicable privacy and data protection laws.</p>
<p>In certain, limited circumstances we may share or transfer personal information to unrelated third parties.  For example, we may provide personal information to a third party (i) at your request; (ii) to comply with a legal requirement or court order; (iii) to investigate a possible crime, such as identity theft; (iv) in connection with the sale, purchase, merger, reorganization, liquidation or dissolution of Simple Mobile Solutions LLC; or (v) under similar circumstances.  If such an event occurs, we will take appropriate steps to protect your personal information.</p>
<h6>Security of Your Personal Information</h6>
<p>Your personal information may generally be stored in Simple Mobile Solutions LLC databases or databases maintained by our service providers. </p>
<p>Simple Mobile Solutions LLC maintains reasonable safeguards to protect the confidentiality, security and integrity of your personal information. </p>
<h6>Access to Personal Information</h6>
<p>You may review, correct and update personal information you provide to us by writing at simplemobilesolutionsmk@gmail.com.</p>
<h6>Retention of Personal Information</h6>
<p>How long we keep your personal information will vary and will depend on the purpose and use of information collected.  There are legal requirements that we keep some types of data for specific periods. Otherwise, we will retain it for no longer than is necessary for the purposes for which the data was collected. </p>
<h6>Disclosures of Personal Information for Monetary or Other Valuable Consideration or for Business Purposes</h6>
<p>We will not disclose email addresses of certain customers of Simple Mobile Solutions LLC to other businesses or third parties for monetary or other valuable consideration. .</p>
<h6>Questions about this Policy or our Privacy Statements</h6>
<p>If you have any questions about this Policy or our use of your personal information, please contact us by writing at simplemobilesolutionsmk@gmail.com. </p>
<h6>Changes to this Policy and our Privacy Statements</h6>
<p>Simple Mobile Solutions LLC reserves the right to update or modify this Policy at any time and without prior notice.  Any modifications will apply only to the personal information we collect after the posting.</p>
<p> <em>This Policy was last revised in January 2022</em></p>



</div>
</div>

            </div>
        </PagesLayout>
      );
    };
export default PrivacyPolicyBulls;
