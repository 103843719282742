import React from 'react';
import { NavLink } from 'react-router-dom';
import NavBar from './NavBar';
import Footer from './Footer';

const PagesLayout = ({children}) => {
    return (
        <div>
         <NavBar />
          {children} 
        <Footer />
        </div>
      );
    };


export default PagesLayout;
