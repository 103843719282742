import React from 'react';
import "./pagesStyles.css";
import PagesLayout from "../components/PageLayout";
import Grid from '@material-ui/core/Grid';
import Mediacard from "../cards/cardsHomePage/Mediacard";
import MediacardGarage from "../cards/cardsHomePage/MediacardGarage";
import MediacardAlarm from "../cards/cardsHomePage/MediacardAlarm";


const Home = () => {
    return (
        <PagesLayout>
            <div className="main-div">
                {/* <div className="header-two">
                    
                </div> */}
                <div className="main-grid">
                <h2>WELCOME TO THE WORLD OF SMART SOLUTIONS. OPEN SMART WITH ORION!</h2>
                <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={4} className="media-card">
                <Mediacard />
                </Grid>
                <Grid item xs={12} sm={6} md={4} className="media-card">
                <MediacardAlarm />
                </Grid>
                <Grid item xs={12} sm={6} md={4} className="media-card">
                <MediacardGarage />
                </Grid>
                </Grid>
                </div>
            </div>
        </PagesLayout>
      );
    };


export default Home;
