import React, { Component } from 'react';
import "./pagesStyles.css";
import PagesLayout from "../components/PageLayout";
import pagesStyles from "../pages/pagesStyles.css";
import fire from "../firebase.js";
import "../photos/smslogo.gif"

class Contact extends Component {

    constructor(props) {
        super(props)
        this.state = {
            username: '',
            email: '',
            phone: '',
            message: '',
            messageStatus: 'not opened'
        }
    }

    handleUsernameChange = (event) => {
        this.setState ({
            username: event.target.value
        })
    }

    handleEmailChange = (event) => {
        this.setState ({
            email: event.target.value
        })
    }

    handlePhoneChange = (event) => {
        this.setState ({
            phone: event.target.value
        })
    }

    handleMessageChange = (event) => {
        this.setState ({
            message: event.target.value
        })
    }

    handleSubmit = (event) => {
        alert (`Your message is sent: 
        Username: ${this.state.username} 
        Email: ${this.state.email} 
        Phone: ${this.state.phone} 
        Message: ${this.state.message}`)
        event.preventDefault()
        let messageRef = fire.database().ref('messages')
        fire.database().ref('messages').push(this.state)
        this.setState({
            username: '',
            email: '',
            messageStatus: 'not opened',
            phone: '',
            message: '' 
        })
    }       


    render() {
    return (
      <PagesLayout>
            <div class="container contact-form">
            <div class="contact-image">
                <img src="smslogo.gif" alt="smslogo"/>
                {/* <img src="https://image.ibb.co/kUagtU/rocket_contact.png" alt="rocket_contact"/> */}
            </div>
            <form 
            onSubmit={this.handleSubmit}>
            {/* method="post"> */}
                <h3>SEND A MESSAGE</h3>
               <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" 
                            name="txtName" 
                            value={this.state.username} 
                            class="form-control" 
                            id="form_name" 
                            placeholder="Name and surname *"
                            required 
                            onChange={this.handleUsernameChange} />
                        </div>
                        <div class="form-group">
                            <input type="Email" 
                            name="txtEmail" 
                            value={this.state.email}
                            class="form-control" 
                            id ="form_email" 
                            placeholder="E-mail *"
                            required
                            onChange={this.handleEmailChange} />
                        </div>
                        <div class="form-group">
                            <input type="text" 
                            name="txtPhone" 
                            value={this.state.phone}
                            class="form-control" 
                            id ="form_phone" 
                            placeholder="Phone number *"
                            required
                            onChange={this.handlePhoneChange} />
                        </div>
                        <div class="form-group">
                            <button type="submit" 
                            name="btnSubmit" 
                            class="btnContact"> 
                            Send a message 
                            </button>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <textarea name="txtMsg" 
                             value={this.state.message}
                            class="form-control" 
                            id="form_message" 
                            placeholder="Write your message here *"
                            required
                            onChange={this.handleMessageChange} 
                            style={{width: '100%', height: '150px'}}>
                            </textarea>
                        </div>
                    </div>
                </div>
            </form>
</div>
        </PagesLayout>
      );
};
}

export default Contact;


